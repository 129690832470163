import React, { useEffect, useState } from "react"
import { Box, Label, Grid, Button } from "theme-ui"
import loadable from "@loadable/component"
const DatePicker = loadable(() => import("react-datepicker"))
import { useLocation } from "@reach/router"

import { registerLocale } from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"
import pl from "date-fns/locale/pl"
registerLocale("pl", pl)
const dateConfig = {
  className: "input-search",
  locale: "pl",
  calendarStartDay: 1,
  dateFormat: "dd.MM.yyyy",
}
const timeConfig = {
  className: "input-search",
  locale: "pl",
  showTimeSelect: true,
  showTimeSelectOnly: true,
  timeFormat: "HH:mm",
  timeCaption: "Time",
  timeIntervals: 30,
  dateFormat: "HH:mm",
}
const getFormattedDate = d => {
  return (
    d.getDate().toString().padStart(2, "0") +
    "." +
    (d.getMonth() + 1).toString().padStart(2, "0") +
    "." +
    d.getFullYear()
  )
}

const getFormattedDateDashes = d => {
  // output: 2023-11-24
  return (
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    d.getDate().toString().padStart(2, "0")
  )
}

const getFormattedtime = d => {
  return (
    d.getHours().toString().padStart(2, "0") +
    ":" +
    d.getMinutes().toString().padStart(2, "0")
  )
}

const encodeQueryData = data => {
  const ret = []
  for (let d in data)
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]))
  return ret.join("&")
}

function SearchFormParking({ isParkVia }) {
  const { pathname: slug } = useLocation()

  const [queryString, setQueryString] = useState("")

  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 7)

  const dayAfterTomorrow = new Date()
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 14)

  const nextHour = new Date()
  nextHour.setDate(tomorrow.getDate() + 7)
  nextHour.setHours(12, 0, 0, 0)

  const tomorrowNoon = new Date()
  tomorrowNoon.setDate(tomorrow.getDate() + 14)
  tomorrowNoon.setHours(12, 0, 0, 0)

  const [startDate, setStartDate] = useState(tomorrow)
  const [startTime, setStartTime] = useState(nextHour)
  const [endDate, setEndDate] = useState(dayAfterTomorrow)
  const [endTime, setEndTime] = useState(tomorrowNoon)

  const updateSearch = () => {
    const sDate = getFormattedDate(startDate)
    const sTime = getFormattedtime(startTime)
    const eDate = getFormattedDate(endDate)
    const eTime = getFormattedtime(endTime)

    const querystring = {
      from_date: sDate,
      arrival_time: sTime,
      departure_time: eTime,
      to_date: eDate,
    }
    // ?from_date=05.08.2021&arrival_time=12%3A00&to_date=06.08.2021&departure_time=12%3A00
    setQueryString(encodeQueryData(querystring))
  }

  useEffect(() => {
    updateSearch()
  }, [])

  useEffect(() => {
    updateSearch()
  }, [startDate, startTime, endDate, endTime])

  const getReservationLink = () => {
    const parkViaQuery = {
      startDate: getFormattedDateDashes(startDate),
      endDate: getFormattedDateDashes(endDate),
      startTime: getFormattedtime(startTime),
      endTime: getFormattedtime(endTime),
      passengers: 1,
    }
    const queryParameters = `startdate=${parkViaQuery.startDate}%20${parkViaQuery.startTime}&enddate=${parkViaQuery.endDate}%20${parkViaQuery.endTime}&passengers=1`

    if (isParkVia) {
      return `https://www.parkvia.com/pl-PL/checkout?producttype=P&location=${
        process.env.GATSBY_PARKVIA_LOCATION
      }&${queryParameters}&operator=${slug.replace(
        "/",
        "",
      )}&affiliateCode=PAPICODE`
    }

    let link = `https://www.parklot.pl/pl/${process.env.GATSBY_PARKING_SLUG}${slug}/`
    link += queryString ? "reservation/" : ""
    link += `${
      queryString ? "?" + queryString + "&" : "?"
    }utm_source=travellead&utm_medium=cps&utm_campaign=1174-baliceparking.pl&a_aid=1174&a_cid=a988c2f2&chan=card`
    return link
  }

  return (
    <Grid gap={1} columns={[1, 2, 2, 5]} id="search">
      <Box mr={2} className="box-date">
        <Label htmlFor="startDate">Data przyjazdu</Label>
        <DatePicker
          id="startDate"
          selected={startDate}
          onChange={date => setStartDate(date)}
          {...dateConfig}
        />
      </Box>
      <Box mr={2}>
        <Label htmlFor="startTime">Godzina przyjazdu</Label>
        <DatePicker
          id="startTime"
          selected={startTime}
          onChange={date => setStartTime(date)}
          {...timeConfig}
        />
      </Box>
      <Box mr={2}>
        <Label htmlFor="endDate">Data wyjazdu</Label>
        <DatePicker
          id="endDate"
          selected={endDate}
          onChange={date => setEndDate(date)}
          {...dateConfig}
        />
      </Box>
      <Box mr={2}>
        <Label htmlFor="endTime">Godzina wyjazdu</Label>
        <DatePicker
          id="endTime"
          className="input-search"
          selected={endTime}
          onChange={date => setEndTime(date)}
          {...timeConfig}
        />
      </Box>
      <Box className="search-btn">
        <a
          target="_blank"
          rel="noreferrer noopener sponsored"
          href={getReservationLink(slug)}
          title={`Link do rezerwacji parkingu ${slug}`}
          onClick={() => {
            // eslint-disable-next-line no-undef
            if (window && window.gtag && typeof window.gtag === "function") {
              // eslint-disable-next-line no-undef
              window.gtag("event", "reservation-click-parking", {
                event_category: slug,
                event_label: queryString,
              })
              if (isParkVia) {
                // eslint-disable-next-line no-undef
                window.gtag("event", "parkvia-reservation-click-parking", {
                  event_category: slug,
                  event_label: queryString,
                })
              }
            }
          }}
        >
          <Button py={2} px={5}>
            {"Rezerwuj"}
          </Button>
        </a>
      </Box>
    </Grid>
  )
}

export default SearchFormParking
